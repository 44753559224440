const baseURL = "https://canadoctors.herokuapp.com/transactions";

const updateTransaction = async (id, datos) => {
  const response = await fetch(`${baseURL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    },
    body: JSON.stringify({ data: datos }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(`Update request failed: ${errorMessage}`);
  }

  /*
  const data = await response.json();
  console.log("data", data);

  return data;
*/
};

export { updateTransaction };
