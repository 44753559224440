import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SingUp from "./steps/SingUp";
import logoPlaneta420 from "../../assets/images/logos/planeta420/planeta420Colors.svg";
import QrATH from "../../assets/images/QRath.svg";
import logoEvertec from "../../assets/images/logos/evertec/evertecLogo.svg";
import PersonalInformationPr from "./steps/PersonalInformationPr";
import PersonalCharacteristicsPr from "./steps/PersonalCharacteristicsPr";
import MedicalInformation from "./steps/MedicalInformation";
import Region from "./steps/Region";
import ProfilePicture from "../../components/ProfilePicture";
import ChekcoutPCuidaDeTi from "../../components/Checkout/CheckoutPCuidaDeTi";
import Coupon from "./steps/Coupon";
import { FaCheckCircle } from "react-icons/fa";

import { getPatientsLastId } from "../../services/getPatients";
import { saveImg } from "../../application/saveImg";
import ATHopNum from "./steps/ATHoperationNum";
import saveTakeCareOfYourself from "../../services/postTakeCareOfYourself";
import Calendar from "../../components/Calendar/Calendar";
import ShiftCalendar from "./steps/ShiftCalendar";
import AlertsMenssages from "../../components/AlertsMenssages";
import EmailValidation from "./steps/Email";
import { updateTransaction } from "../../services/evertec/updateTransactionEvertec";
import { postEvertec } from "../../services/evertec/postEvertec";
import evertecAuth from "../../services/evertec/evertecAuth";
import Modal from "../../components/Modal";
import Faq from "../../components/Faq";
import Evertec from "../../components/Checkout/Evertec";

const FinishSectionButton: React.FC<{
  onClick: () => void;
  isDisabled: boolean;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
}> = ({ onClick, isDisabled, children }) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      type="button"
      className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
    >
      {children}
    </button>
  );
};

const descriptionEvertec = "Planeta 420";
const priceCuidaDeTi = 45;
const priceCuidaDeTiAcompañamiento = 60;

const discountPercentage = 25;

declare const P: any;

function TakeCareOfYourselfPlanteta420() {
  const [lastUserId, setLastUserId] = useState(null);
  const [formStep, setFormStep] = useState(0);

  const [imgLicencse, setImgLicense] = useState<File | null>(null);
  const [imgProfile, setImgProfile] = useState<File | null>(null);
  const [imgDocId, setImgDocId] = useState<File | null>(null);

  const [transactionData, setTransactionData] = useState<any>(null);
  const [imgData, setImgData] = useState<any>(null);

  const [idSocialSecurity, setIdSocialSecurity] = useState<any>(null);

  const [discountedPrice, setDiscountedPrice] =
    useState<number>(priceCuidaDeTi);

  const [userPrich, setUserPrich] = useState<boolean>(false);

  const [buttonType, setButtonType] = useState<string>("");

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const [buttonATH, setButtonATH] = useState<boolean>(false);
  const [ATHCompleted, setATHCompleted] = useState<boolean>(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [saveUserId, setSaveUserId] = useState<any>(null);
  const [dataUser, setDataUser] = useState<any>({});

  //EVERTEC
  const [evertecStatus, setEvertecStatus] = useState<string | null>(null);
  const [dataForm, setDataForm] = useState({});

  const handleEvertecStatusChange = (status: string) => {
    setEvertecStatus(status);
  };

  //validacion step 1
  const [emailExists, setEmailExists] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailExistence = (existe: any | null) => {
    if (existe) {
      setDataUser(existe);
      setSaveUserId(Object.values(existe)[0] || "");
      setIdSocialSecurity(Object.values(existe)[1] || "");
      setEmailExists(existe);
    } else {
      // Lógica para manejar el caso en que el paciente no existe
      setDataUser(null);
      setSaveUserId("");
      setIdSocialSecurity("");
      setEmailExists(false);
    }
  };

  //CANTIDAD DE STEP
  const STEPS_AMOUNT = emailExists ? 4 : 10;

  //calendar
  const [dateFactory, setDateFactory] = useState<string | null>(null);

  //validacion el id del empleado PRICH
  const [employeeId, setEmployeeId] = useState("");
  const [employeeIdExists, setEmployeeIdExists] = useState(false);
  const [employeeIdCompleted, setEmployeeIdCompleted] =
    useState<boolean>(false);
  const handleEmployeeIdExistence = (existe: any) => {
    setEmployeeIdExists(existe);
  };
  const handleEmployeeIdChange = (newEmployeeId: any) => {
    setEmployeeId(newEmployeeId);
  };

  const handlePaymentComplete = (value: boolean) => {
    setPaymentCompleted(value);
  };

  useEffect(() => {
    const fetchLastUserId = async () => {
      try {
        const lastId = await getPatientsLastId();
        setLastUserId(lastId + 1);
      } catch (error) {
        console.error("Error al obtener el último ID de usuario:", error);
        // Manejar el error de forma adecuada en tu componente
      }
    };

    fetchLastUserId();
  }, [lastUserId]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const typeOfLicense = watch("typeOfLicense");
  const location = watch("location");
  const coupon = watch("coupon");
  const scheduleRange = watch("question");

  function handleStepCompletion() {
    setFormStep((cur) => cur + 1);
  }

  function handleGoBackToPreviousStep() {
    setFormStep((cur) => cur - 1);
  }

  const handleImageLicense = (image: File | null) => {
    setImgLicense(image);
  };

  const handleImgProfile = (imgProfile: File | null) => {
    setImgProfile(imgProfile);
  };

  const handleImageDocId = (image: File | null) => {
    setImgDocId(image);
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true); // Inicia la carga

      // Otras lógicas del formulario...
      setFormStep(emailExists ? 1 : 1);
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    } finally {
      setLoading(false); // Detiene la carga al finalizar
    }
  };

  //handle de los cupones
  const handleDiscountCoupon = () => {
    if (coupon === "20PLANETA4204") {
      const discountAmount = (priceCuidaDeTi * discountPercentage) / 100;
      setDiscountedPrice(priceCuidaDeTi - discountAmount);
    } else {
      setDiscountedPrice(priceCuidaDeTi);
    }

    if (coupon === "PRICH2023") {
      setUserPrich(true);

      if (employeeIdExists) {
        setDiscountedPrice(0);
      } else {
        setDiscountedPrice(priceCuidaDeTi);
      }
    } else {
      setUserPrich(false);
    }
  };

  const handleDateChange = (date: Date) => {
    if (date) {
      const dateRefactory = date.toLocaleDateString("es-AR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      setDateFactory(dateRefactory);
    }
  };

  //SUBMIT DE REGISTRO
  const onSubmit = async (values: any) => {
    const singUpData = {
      lastName: values.lastName,
      firstName: values.firstName,
      email: values.email,
      password: values.password,
      phone: "+1" + values.phone,
      active: true,
      doctor: false,
      medicalRecordComplete: true,
      country: "PR",
      reference: 7,
      rol: 1,
      youMeetUs: values.whereMet,
    };

    // valores para la pasarela de pago EVERTEC
    const { firstName, lastName, email, phone } = singUpData;
    setDataUser({ firstName, lastName, email, phone });

    const medicalRecordData = {
      adult: values.adult,
      gender: values.gender,
      birthDate: values.birthDate,
      socialSecurity: values.socialSecurity,

      weight: values.weight,
      foots: values.foots,
      inches: values.inches,
      eyesColor: values.eyesColor,

      profession: "",

      address: values.address,
      zipCode: values.zipCode,

      city: values.city,
    };

    const imgFileData = [imgLicencse, imgProfile, imgDocId];

    setTransactionData(transactionData);
    setImgData(imgFileData);
    setIdSocialSecurity(values.socialSecurity);

    const saveData = await saveTakeCareOfYourself(
      singUpData,
      medicalRecordData,
      imgFileData
    );
    setSaveUserId(saveData);
  };

  //SUBMIT DE PAGO
  const onSubmitPayment = async (values: any) => {
    const ath = async () => {
      try {
        const imgFileData = [imgLicencse, imgProfile, imgDocId];
        const uploadedImageURLs = await saveImg(imgFileData, idSocialSecurity);

        const datosTransactionATH = {
          type: typeOfLicense,
          dispensaryLocation: parseInt(location),
          patientId: saveUserId,
          amount: discountedPrice,
          method: "ATH",
          state: "pendiente",
          date: new Date(),
          orderId: values.orderId,
          status: 0,
          scheduleDate: dateFactory,
          scheduleRange: values.question,
          urlVoucherImg: uploadedImageURLs[0] || "",
        };

        const transactionResponse = await fetch(
          "https://canadoctors.herokuapp.com/transactions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
            },
            body: JSON.stringify({ data: datosTransactionATH }),
          }
        );

        if (!transactionResponse.ok) {
          throw new Error("Failed to insert transaction data.");
        }

        setATHCompleted(true);
      } catch (error) {
        console.error("Error:", error);
        alert(
          "Hubo un error al guardar los datos. Por favor, inténtalo de nuevo."
        );
      }
    };

    if (buttonATH) {
      ath();
      setFormSubmitted(true);
    }

    if (buttonType === "PayPal") {
      const dataForm = {
        scheduleDate: dateFactory,
        scheduleRange: scheduleRange,
        dispensaryLocation: parseInt(location),
        urlVoucherImg: [imgLicencse, imgProfile, imgDocId],
      };
      setDataForm(dataForm);
    }

    const employeePrich = async () => {
      try {
        const imgFileData = [imgLicencse, imgProfile, imgDocId];
        const uploadedImageURLs = await saveImg(imgFileData, idSocialSecurity);

        const datosTransactionPRICH = {
          type: typeOfLicense,
          dispensaryLocation: parseInt(location),
          patientId: saveUserId,
          amount: discountedPrice,
          method: "FREE",
          state: "COMPLETED",
          date: new Date(),
          orderId: employeeId,
          status: 1,
          scheduleDate: dateFactory,
          scheduleRange: values.question,
          urlVoucherImg: uploadedImageURLs[0],
        };

        const transactionResponse = await fetch(
          "https://canadoctors.herokuapp.com/transactions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
            },
            body: JSON.stringify({ data: datosTransactionPRICH }),
          }
        );

        if (!transactionResponse.ok) {
          throw new Error("Failed to insert transaction data.");
        }

        const specialPatientPRICH = await fetch(
          `https://canadoctors.herokuapp.com/special/${employeeId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
            },
            body: JSON.stringify({ data: { redeem: true } }),
          }
        );

        if (!specialPatientPRICH.ok) {
          throw new Error("Failed to update special patient data.");
        }

        setEmployeeIdCompleted(true);
      } catch (error) {
        console.error("Error:", error);
        alert(
          "Hubo un error al guardar los datos. Por favor, inténtalo de nuevo."
        );
      }
    };

    if (buttonType === "Employee") {
      employeePrich();
      setFormSubmitted(true);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#00A099]/80 to-[#8CBB93]/90 bg-opacity-40 min-h-screen flex flex-col justify-center items-center">
      <div className="absolute w-60 h-60 rounded-xl bg-white -top-5 -left-16 z-0 transform rotate-45 hidden md:block"></div>
      <div className="absolute w-48 h-48 rounded-xl bg-white bottom-6 right-6 transform rotate-12 hidden md:block"></div>
      <img src={logoPlaneta420} alt="logo" width={200} />
      <div className="max-w-4xl lg:w-full w-[95vw] py-16 sm:px-14 px-8 m-4 bg-white rounded-2xl shadow-xl flex flex-col justify-center z-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStep < STEPS_AMOUNT && (
            <div className="flex items-center font-medium mb-8">
              {formStep > 0 && (
                <button
                  onClick={handleGoBackToPreviousStep}
                  type="button"
                  className="focus:outline-none"
                >
                  <svg
                    viewBox="0 0 20 20"
                    version="1.1"
                    className="w-6 mr-2 fill-current text-gray-300 hover:text-gray-400 focus:outline-none"
                  >
                    <polygon
                      id="Combined-Shape"
                      points="10 13 18 13 18 7 10 7 10 2 2 10 10 18 10 13"
                    ></polygon>
                  </svg>
                </button>
              )}
              Etapa {formStep + 1} de {STEPS_AMOUNT}
            </div>
          )}

          {formStep >= 0 && (
            <section className={`${formStep === 0 ? "block" : "hidden"}`}>
              <h2 className="font-semibold text-3xl mb-4">Validar correo</h2>
              <p>Por Favor, ingrese su correo electronico.</p>

              <EmailValidation
                register={register}
                errors={errors}
                watch={watch}
                emailExists={emailExists}
                onEmailExistence={handleEmailExistence}
                setLoading={setLoading}
              />

              <FinishSectionButton
                onClick={handleFormSubmit}
                isDisabled={!isValid || loading}
              >
                {loading ? (
                  <svg
                    role="status"
                    className="inline h-8 w-8 animate-spin mr-2 text-gray-200 fill-cdgreen"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  "Siguiente"
                )}
              </FinishSectionButton>
            </section>
          )}

          {!emailExists && (
            <>
              {formStep >= 1 && (
                <section className={`${formStep === 1 ? "block" : "hidden"}`}>
                  <h2 className="font-semibold text-3xl mb-4">
                    Información Personal
                  </h2>
                  <p>
                    Por Favor, ingrese su nombre y apellido de forma completa
                    tal como figura en su Real ID.
                  </p>

                  <SingUp
                    register={register}
                    errors={errors}
                    watch={watch}
                    emailExists={emailExists}
                    onEmailExistence={handleEmailExistence}
                  />

                  <FinishSectionButton
                    onClick={handleStepCompletion}
                    isDisabled={!isValid || emailExists}
                  >
                    Siguiente
                  </FinishSectionButton>
                </section>
              )}

              {formStep >= 2 && (
                <section className={`${formStep === 2 ? "block" : "hidden"}`}>
                  <h2 className="font-semibold text-3xl mb-4">
                    Información Personal
                  </h2>

                  <PersonalInformationPr register={register} errors={errors} />

                  <FinishSectionButton
                    onClick={handleStepCompletion}
                    isDisabled={!isValid}
                  >
                    Siguiente
                  </FinishSectionButton>
                </section>
              )}

              {formStep >= 3 && (
                <section className={`${formStep === 3 ? "block" : "hidden"}`}>
                  <h2 className="font-semibold text-3xl mb-4">
                    Características Personales
                  </h2>

                  <PersonalCharacteristicsPr
                    register={register}
                    errors={errors}
                  />

                  <FinishSectionButton
                    onClick={handleStepCompletion}
                    isDisabled={!isValid}
                  >
                    Siguiente
                  </FinishSectionButton>
                </section>
              )}

              {formStep >= 4 && (
                <section className={`${formStep === 4 ? "block" : "hidden"}`}>
                  <h2 className="font-semibold text-3xl mb-4">
                    Información Médica
                  </h2>

                  <MedicalInformation register={register} errors={errors} />

                  <FinishSectionButton
                    onClick={handleStepCompletion}
                    isDisabled={!isValid}
                  >
                    Siguiente
                  </FinishSectionButton>
                </section>
              )}

              {formStep >= 5 && (
                <section className={`${formStep === 5 ? "block" : "hidden"}`}>
                  <div className="mb-4">
                    <h2 className="font-semibold text-3xl">Foto de Perfil</h2>
                    <div className="flex flex-col">
                      <span className="font-light">
                        - Selfie con fondo blanco/claro
                      </span>
                      <span className="font-light">
                        - Rostro descubierto, sin lentes o accesorios
                      </span>
                    </div>
                  </div>

                  <ProfilePicture
                    onImageChange={handleImgProfile}
                    inputId="perfil"
                    perfil
                  />

                  <FinishSectionButton
                    onClick={handleStepCompletion}
                    isDisabled={!isValid || imgProfile === null}
                  >
                    Siguiente
                  </FinishSectionButton>
                </section>
              )}

              {formStep >= 6 && (
                <section className={`${formStep === 6 ? "block" : "hidden"}`}>
                  <h2 className="font-semibold text-3xl">Foto de Real ID</h2>
                  <div className="flex flex-col mb-4">
                    <span className="font-light">
                      - Se acepta real ID, licencia de conducir PR/USA (vigente)
                      o pasaporte
                    </span>
                    <span className="font-light">
                      - No se acepta la tarjeta electoral
                    </span>
                  </div>

                  <ProfilePicture
                    onImageChange={handleImageDocId}
                    inputId="docId"
                  />

                  <button
                    type={"submit"}
                    disabled={!isValid || imgDocId === null}
                    className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                    onClick={handleStepCompletion}
                  >
                    Continuar
                  </button>
                </section>
              )}
            </>
          )}
        </form>

        <form onSubmit={handleSubmit(onSubmitPayment)}>
          {formStep >= (!emailExists ? 7 : 1) && (
            <section
              className={`${
                formStep === (!emailExists ? 7 : 1) ? "block" : "hidden"
              }`}
            >
              {saveUserId ? (
                <>
                  <h2 className="font-semibold text-3xl mb-4">
                    Tipo de Licencia
                  </h2>
                  <Region register={register} errors={errors} />
                  {(typeOfLicense === "Renovación de Licencia" ||
                    typeOfLicense === "Renovación de Licencia - Emancipado" ||
                    typeOfLicense ===
                      "Renovación de Licencia - Licencia Acompañante") && (
                    <div className="mt-6">
                      <div className="mb-4">
                        <h2 className="font-semibold text-3xl">
                          Foto del voucher
                        </h2>
                        <span className="font-light">- Vencido o a vencer</span>
                      </div>
                      <ProfilePicture
                        onImageChange={handleImageLicense}
                        inputId="license"
                      />
                    </div>
                  )}
                  <FinishSectionButton
                    onClick={handleStepCompletion}
                    isDisabled={
                      !isValid ||
                      ((typeOfLicense === "Renovación de Licencia" ||
                        typeOfLicense ===
                          "Renovación de Licencia - Emancipado" ||
                        typeOfLicense ===
                          "Renovación de Licencia - Licencia Acompañante") &&
                        imgLicencse === null)
                    }
                  >
                    Siguiente
                  </FinishSectionButton>
                </>
              ) : (
                <div className="w-full flex flex-col justify-center items-center">
                  <svg
                    role="status"
                    className="inline h-14 w-14 animate-spin mr-2 text-gray-400 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <h1 className="mt-4 text-gray-500 text-2xl">
                    Cargando pasarelas de pago...
                  </h1>
                </div>
              )}
            </section>
          )}

          {formStep >= (!emailExists ? 8 : 2) && (
            <section
              className={`${
                formStep === (!emailExists ? 8 : 2) ? "block" : "hidden"
              }`}
            >
              <h2 className="font-semibold text-3xl mb-4">
                Seleccionar Fecha de llamado
              </h2>

              <div className="flex flex-col md:flex-row">
                <Calendar onDateChange={handleDateChange} />
                <div className="ml-0 md:ml-4">
                  <h2 className="font-semibold text-lg mb-4">
                    Fecha seleccionada:{" "}
                    <span className="text-lg text-cdgreen">
                      {dateFactory || "No hay fecha"}
                    </span>
                  </h2>

                  <ShiftCalendar register={register} errors={errors} />

                  <div className="mt-4">
                    <AlertsMenssages
                      type="warning"
                      message="La validez de esta fecha está sujeta a la recepción adecuada de la documentación y recepción del pago, puede estar condicionada a posibles modificaciones."
                    />
                  </div>
                </div>
              </div>

              <FinishSectionButton
                onClick={handleStepCompletion}
                isDisabled={!isValid || !dateFactory}
              >
                Siguiente
              </FinishSectionButton>
            </section>
          )}

          {formStep >= (!emailExists ? 9 : 3) && (
            <section
              className={`${
                formStep === (!emailExists ? 9 : 3) ? "block" : "hidden"
              }`}
            >
              <>
                <div className="flex flex-col justify-center items-center">
                  <div className="bg-gray-100 flex flex-col justify-center items-center text-black shadow-lg shadow-gray-300 w-full h-auto p-4 rounded-2xl relative mx-12">
                    <p className="text-black font-semibold capitalize text-lg">
                      Video consulta médica
                    </p>
                    <p className="text-black text-md mt-5">
                      - Acceso a plataforma Canadoctors, consulta con médico
                      autorizado por la junta de cannabis medicinal.
                    </p>
                    <p className="text-black text-lg mt-5 font-bold">
                      {typeOfLicense}
                    </p>
                    <span className="text-lg font-extrabold mt-5">
                      Total:{" "}
                      {typeOfLicense ===
                        "Nueva Licencia - Licencia Acompañante" ||
                      typeOfLicense ===
                        "Renovación de Licencia - Licencia Acompañante"
                        ? priceCuidaDeTiAcompañamiento + ".00"
                        : discountedPrice === 45
                        ? discountedPrice.toFixed(2)
                        : discountedPrice.toFixed(2)}
                    </span>
                  </div>
                </div>
                {/* typeOfLicense !== "Turista" && (
                  <div className="flex flex-col justify-center gap-2 lg:flex-row mt-8">
                    <div className="lg:w-3/4 w-full">
                      <Coupon
                        register={register}
                        errors={errors}
                        employee={userPrich}
                        onEmployeeIdExistence={handleEmployeeIdExistence}
                        onEmployeeIdChange={handleEmployeeIdChange}
                      />
                    </div>

                    {!userPrich ? (
                      <button
                        className="border-[#00A099] text-black border rounded py-2 px-4 hover:text-[#00A099]"
                        type="button"
                        onClick={handleDiscountCoupon}
                      >
                        Cargar Cupón
                      </button>
                    ) : (
                      <button
                        className="border-[#00A099] text-black border rounded py-2 px-4 hover:text-[#00A099]"
                        type="button"
                        onClick={handleDiscountCoupon}
                      >
                        Cargar ID
                      </button>
                    )}
                  </div>
                )*/}

                <FinishSectionButton
                  isDisabled={!isValid}
                  onClick={handleStepCompletion}
                >
                  Siguiente
                </FinishSectionButton>
              </>
            </section>
          )}

          {formStep >= (!emailExists ? 10 : 4) && (
            <section
              className={`${
                formStep === (!emailExists ? 10 : 4) ? "block" : "hidden"
              }`}
            >
              <h2 className="font-semibold text-3xl mb-4">Pagos</h2>

              {buttonType === "" && !employeeIdExists && (
                <>
                  {/*    <button
                    className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                    type="button"
                    onClick={() => setButtonType("ATH")}
                  >
                    ATH
                  </button>
              */}
                  <button
                    className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                    type="button"
                    onClick={() => setButtonType("PayPal")}
                  >
                    CREDITO / DEBITO
                  </button>

                  <button
                    className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                    type="button"
                    onClick={() => setFormStep(formStep - 1)}
                  >
                    ATRAS
                  </button>
                </>
              )}

              {employeeIdExists && !employeeIdCompleted && (
                <button
                  className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                  type="submit"
                  onClick={() => {
                    setButtonType("Employee");
                  }}
                  disabled={formSubmitted}
                >
                  Finalizar solicitud
                </button>
              )}

              {employeeIdCompleted && (
                <div className="flex flex-col justify-center items-center">
                  <FaCheckCircle className="text-8xl mb-4 text-cdgreen" />

                  <p className="mb-4 text-2xl tracking-tight font-bold text-black md:text-4xl">
                    Pago realizado con éxito
                  </p>
                  <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                    En unos instantes recibirá un email, por favor revise su
                    casilla de email para tener más información. Pronto un
                    médico autorizado se estará comunicando con usted, le
                    pedimos estar atento/a a la llamada.
                  </p>
                </div>
              )}

              {buttonType === "ATH" && (
                <>
                  {ATHCompleted ? (
                    <div className="flex flex-col justify-center items-center">
                      <FaCheckCircle className="text-8xl mb-4 text-cdgreen" />

                      <p className="mb-4 text-2xl tracking-tight font-bold text-black md:text-4xl">
                        Pago realizado con éxito
                      </p>
                      <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        En unos instantes recibirá un email, por favor revise su
                        casilla de email para tener más información. Pronto un
                        médico autorizado se estará comunicando con usted, le
                        pedimos estar atento/a a la llamada.
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-col justify-center items-center">
                        <div className="bg-gray-100 flex flex-col justify-center items-center text-black shadow-lg shadow-gray-300 w-full h-auto p-4 rounded-2xl relative mx-12">
                          <p className="text-black font-semibold capitalize text-lg">
                            Video consulta médica
                          </p>
                          <p className="text-black text-md mt-5">
                            - Acceso a plataforma Canadoctors, consulta con
                            médico autorizado por la junta de cannabis
                            medicinal.
                          </p>
                          <p className="text-black text-lg mt-5 font-bold">
                            {typeOfLicense}
                          </p>
                          <span className="text-lg font-extrabold mt-5">
                            Total:{" "}
                            {typeOfLicense ===
                              "Nueva Licencia - Licencia Acompañante" ||
                            typeOfLicense ===
                              "Renovación de Licencia - Licencia Acompañante"
                              ? priceCuidaDeTiAcompañamiento + ".00"
                              : discountedPrice === 45
                              ? discountedPrice.toFixed(2)
                              : discountedPrice.toFixed(2)}
                          </span>
                          <div className="flex flex-col border-2 items-center justify-center text-center border-dotted border-gray-700 rounded-xl mt-4 p-4">
                            <p className="text-black text-lg font-bold">
                              - Informacion de cuenta ATH a transferir:
                            </p>
                            <img
                              className="flex"
                              src={QrATH}
                              alt="qrATH"
                              width={300}
                            />
                            <p className="text-black text-2xl mt-1 font-bold">
                              (787)-923-1388
                            </p>
                            <p className="text-black text-md mt-2 text-center font-semibold">
                              Una vez realizado el deposito, enviar el
                              comprobante al número indicado arriba y volver
                              aquí al formulario para ingresar el numero de
                              referencia.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2">
                        <ATHopNum register={register} errors={errors} />
                      </div>
                      <button
                        className="mt-6 bg-cdgreen text-xl font-bold text-white rounded-xl hover:opacity-80 py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                        type="submit"
                        onClick={() => {
                          setButtonATH(true);
                        }}
                        disabled={formSubmitted || !isValid}
                      >
                        Finalizar solicitud
                      </button>

                      <button
                        className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                        type="button"
                        onClick={() => setButtonType("")}
                      >
                        Otros medios de pago
                      </button>
                    </>
                  )}
                </>
              )}

              {buttonType === "PayPal" && (
                <>
                  {evertecStatus === "APPROVED" ||
                  evertecStatus === "PENDING" ? (
                    <div className="flex flex-col justify-center items-center">
                      <FaCheckCircle
                        className={`text-8xl mb-4 ${
                          evertecStatus === "APPROVED"
                            ? "text-cdgreen"
                            : "text-yellow-500"
                        } `}
                      />

                      <p className="mb-4 text-2xl tracking-tight font-bold text-black md:text-4xl">
                        {evertecStatus === "APPROVED"
                          ? "Pago realizado con éxito"
                          : "Pago pendiente de aprobación"}
                      </p>
                      <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        En unos instantes recibirá un email, por favor revise su
                        casilla de email para tener más información. Pronto un
                        médico autorizado se estará comunicando con usted, le
                        pedimos estar atento/a a la llamada.
                      </p>
                    </div>
                  ) : (
                    <>
                      {/* <ChekcoutPCuidaDeTi
                        idSocialSecurity={idSocialSecurity}
                        typeLicence={typeOfLicense}
                        price={discountedPrice}
                        userId={saveUserId}
                        transactionData={transactionData}
                        imgData={imgData}
                        onPaymentComplete={handlePaymentComplete}
                  />*/}

                      <div className="flex flex-col justify-center items-center">
                        <div className="bg-gray-100 flex flex-col justify-center items-center text-black shadow-lg shadow-gray-300 w-full h-auto p-4 rounded-2xl relative mx-12">
                          <p className="text-black font-semibold capitalize text-lg">
                            Video consulta médica
                          </p>
                          <p className="text-black text-md mt-5">
                            Acceso a plataforma Canadoctors, consulta con
                            especialista médico en endocannabinología.
                          </p>
                          <p className="text-black text-lg mt-5 font-bold">
                            {typeOfLicense}
                          </p>

                          <span className="text-lg font-extrabold mt-5">
                            Total: $
                            {typeOfLicense ===
                              "Nueva Licencia - Licencia Acompañante" ||
                            typeOfLicense ===
                              "Renovación de Licencia - Licencia Acompañante"
                              ? priceCuidaDeTiAcompañamiento + ".00"
                              : discountedPrice === 45
                              ? discountedPrice.toFixed(2)
                              : discountedPrice.toFixed(2)}
                          </span>
                        </div>
                        <button className="w-full" type="submit">
                          <Evertec
                            dataUser={dataUser}
                            amount={
                              typeOfLicense.includes(
                                "Nueva Licencia - Licencia Acompañante"
                              ) ||
                              typeOfLicense.includes(
                                "Renovación de Licencia - Licencia Acompañante"
                              )
                                ? priceCuidaDeTiAcompañamiento
                                : discountedPrice
                            }
                            reference={descriptionEvertec}
                            userId={saveUserId}
                            typeOfLicense={typeOfLicense}
                            socialSecurity={idSocialSecurity}
                            dataForm={dataForm}
                            onEvertecStatusChange={handleEvertecStatusChange}
                          />
                        </button>
                      </div>

                      <button
                        className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
                        type="button"
                        onClick={() => setButtonType("")}
                      >
                        Cambiar medio de pago
                      </button>
                    </>
                  )}
                </>
              )}
            </section>
          )}

          {/*
           {user.country === "puerto rico" ? <ChekcoutP /> : <CheckoutAr />}
          <p>{isValid ? "Valid" : "Invalid"}</p>
            <pre className="text-sm text-gray-700">
              {JSON.stringify(watch(), null, 2)}
            </pre>*/}
        </form>
      </div>
      <div className="w-40 h-40 absolute bg-white rounded-full top-0 right-12 hidden md:block"></div>
      <div className="w-20 h-40 absolute bg-white rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
    </div>
  );
}

export default TakeCareOfYourselfPlanteta420;
