import { FaCheckCircle } from "react-icons/fa";

function FinishPayment() {
  return (
    <div className="h-[100vh] flex items-center justify-center bg-gradient-to-b from-[#00A099]/80 to-[#8CBB93]/90 bg-opacity-40 p-2">
      <div className="flex flex-col justify-center items-center max-w-4xl shadow-xl p-10 rounded-xl bg-white py-20">
        <FaCheckCircle className="text-8xl mb-4 text-yellow-500" />

        <p className="mb-4 text-xl tracking-tight font-bold text-black md:text-4xl">
          Pago en revisión
        </p>
        <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400 text-center">
          Estamos procesando su pago y en breve recibirá un correo electrónico
          de confirmación. Le solicitamos que revise su bandeja de entrada para
          obtener información adicional.
        </p>
      </div>
    </div>
  );
}

export default FinishPayment;
