import { useEffect, useState } from "react";
import CardsPriority from "../../../components/CardsPriority/CardsPriority";
import { useAuthContext } from "../../../contexts/authContext";
import LoadingComponent from "../../../components/LoadingComponent";
import {
  getTransactions,
  getTransactionsReference,
} from "../../../services/getTransactions";
import referenceStatusArray from "../../../components/data/referenceStatusArray";

interface Patient {
  name: string;
  lastName: string;
  email: string;
  phone: number;
  id: string;
  medicalRecordComplete: boolean;
  transactions: Transaction[];
  country: string;
  urlProfileImg: string;
  date: string;
  status: number; // Add a 'status' property to the Patient interface
}

interface Transaction {
  id: string;
  amount: number;
  date: string;
  type: string;
}

function PatientsPriority() {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [allPatients, setAllPatients] = useState<Patient[]>([]);
  const [selectedReference, setSelectedReference] = useState<number>(1);
  //console.log(selectedReference);

  useEffect(() => {
    setIsLoading(true);

    if (user.rol === 3) {
      getTransactionsReference(user.reference).then((data: Patient[]) => {
        setAllPatients(data);
        setIsLoading(false);
      });
    }

    if (user.rol === 4) {
      getTransactions().then((data: Patient[]) => {
        setAllPatients(data);
        setIsLoading(false);
      });
    }
  }, [user.rol]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {user.rol === 4 && (
        <div>
          <div className="w-full text-center">
            <h1 className="font-bold text-4xl text-black pb-6">
              Lista de Prioridad
            </h1>
          </div>
          {referenceStatusArray.map((status, index) => (
            <button
              key={status.value}
              className={`mr-2 rounded-full p-2 text-xs font-semibold text-white my-1 lg:my-1 ${
                selectedReference === status.value
                  ? "bg-cdgreen"
                  : "bg-gray-500"
              }`}
              onClick={() => setSelectedReference(status.value)}
            >
              {status.label}
            </button>
          ))}
          <CardsPriority
            data={allPatients}
            referenceStatus={selectedReference}
          />
        </div>
      )}
    </>
  );
}

export default PatientsPriority;
