import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/calendar.css";
registerLocale("es", es);

interface CalendarProps {
  onDateChange: (date: Date) => void;
}

const Calendar: React.FC<CalendarProps> = ({ onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const isTodayOrTomorrow = (date: Date) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return (
      (date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()) ||
      (date.getDate() === tomorrow.getDate() &&
        date.getMonth() === tomorrow.getMonth() &&
        date.getFullYear() === tomorrow.getFullYear())
    );
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Permitir solo días de lunes a viernes
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    onDateChange(date);
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 46); // Establecer la fecha máxima a 46 días a partir de la fecha actual

  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        filterDate={(date) =>
          isWeekday(date) && date >= new Date() && !isTodayOrTomorrow(date)
        } // Filtrar solo días de lunes a viernes, fechas futuras y no incluir hoy ni mañana
        locale="es"
        dateFormat="dd/MM/yyyy"
        minDate={new Date()}
        maxDate={maxDate}
        placeholderText="Selecciona una fecha"
        inline
      />
    </div>
  );
};

export default Calendar;
