const referenceNames: Record<number, string> = {
    1: "Canadoctors",
    2: "Strain",
    3: "El Planteo",
    4: "Sabia Collective",
    5: "First Medical",
    6: "Tetra",
    7: "Planeta 420",
    8: "W.Heals"
  };
  
  export default referenceNames;