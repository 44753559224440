import { useAuthContext } from "../../contexts/authContext";
import { useForm, SubmitHandler } from "react-hook-form";
import { EMAIL, PASSWORD } from "../../validations/SignIN";
import { usePasswordToggle } from "../../hooks/usePasswordToggle";
import LogoCanadoctors from "../../assets/images/logos/canadoctors/canadoctorsColors.svg";
import { Link } from "react-router-dom";
import FormField from "../../components/FormFiel/FormFiel";
import AlertsMenssages from "../../components/AlertsMenssages";

type Inputs = {
  email: string;
  password: string;
};

function SignIn() {
  const [passwordInputType, toggleIcon] = usePasswordToggle();
  const { login, isLoginLoading, hasLoginError } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await login(data);
  };
  return (
    <div className="bg-gradient-to-b from-[#00A099]/80 to-[#8CBB93]/90 bg-opacity-40 min-h-screen flex justify-center items-center">
      <div className="absolute w-60 h-60 rounded-xl bg-white -top-5 -left-16 z-0 transform rotate-45 hidden md:block"></div>
      <div className="absolute w-48 h-48 rounded-xl bg-white bottom-6 right-6 transform rotate-12 hidden md:block"></div>
      <div className="max-w-xl w-full py-16 sm:px-14 px-8 m-4 bg-white rounded-2xl shadow-xl flex flex-col justify-center z-10">
        {hasLoginError ? (
          <div>
            <div className="flex justify-center">
              <img src={LogoCanadoctors} alt="logo" width={300} />
            </div>

            <div className="pt-6 pb-2">
              <AlertsMenssages
                type="danger"
                message="Usuario o contraseña invalidas"
              />
            </div>
          </div>
        ) : (
          <div className="pb-10 flex justify-center">
            <img src={LogoCanadoctors} alt="logo" width={300} />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <FormField
              id="email"
              name="email"
              required={true}
              type="email"
              maxLength={35}
              minLength={6}
              placeholder="Correo Electrónico"
              errors={errors}
              register={register}
              validation={EMAIL}
            />
            <div className="flex flex-col relative">
              <FormField
                id="password"
                name="contraseña"
                required={true}
                type={passwordInputType as any}
                minLength={8}
                maxLength={16}
                placeholder="Contraseña"
                errors={errors}
                register={register}
                validation={PASSWORD}
                toggleIcon={toggleIcon}
              />
            </div>
          </div>
          <div className="text-center mt-6">
            {isLoginLoading ? (
              <button
                type="submit"
                className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3  rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
              >
                <div className="w-full flex justify-center">
                  <div className="border-t-transparent border-solid animate-spin rounded-full border-white border-4 h-7 w-7"></div>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3  rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
              >
                Iniciar Sesión
              </button>
            )}
          </div>
        </form>
        <div className="text-center mt-6">
          <p className="mt-4 text-sm">
            ¿No tienes una cuenta?{" "}
            <Link
              to="/signup"
              className="text-sm text-[#5FBAA8] hover:opacity-75"
            >
              Regístrate
            </Link>
          </p>
        </div>
      </div>
      <div className="w-40 h-40 absolute bg-white rounded-full top-0 right-12 hidden md:block"></div>
      <div className="w-20 h-40 absolute bg-white rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
    </div>
  );
}

export default SignIn;
