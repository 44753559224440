import React, { useEffect, useState } from "react";
import evertecAuth from "../../services/evertec/evertecAuth";
import logoEvertec from "../../assets/images/logos/evertec/evertecLogo.svg";
import Modal from "../Modal";
import Faq from "../Faq";
import { saveImg } from "../../application/saveImg";
import { postEvertec } from "../../services/evertec/postEvertec";
import { updateTransaction } from "../../services/evertec/updateTransactionEvertec";

type EvertecProps = {
  dataUser: object;
  amount: number;
  reference: string;
  userId: number;
  typeOfLicense: string;
  socialSecurity: string;
  dataForm: any;
  onEvertecStatusChange: (status: string) => void;
};

declare const P: any;

function Evertec({
  dataUser,
  amount,
  reference,
  userId,
  typeOfLicense,
  socialSecurity,
  dataForm,
  onEvertecStatusChange,
}: EvertecProps) {
  const [processingEvertec, setProcessingEvertec] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [urlEvertec, SetUrlEvertec] = useState(null);
  const [requestId, setRequestId] = useState();

  const { scheduleDate, scheduleRange, dispensaryLocation, urlVoucherImg } =
    dataForm;

  const handleEvertecAuth = async () => {
    try {
      setProcessingEvertec(true);
      setIsButtonDisabled(true);
      const response = await evertecAuth(dataUser, amount, reference);

      if (response) {
        SetUrlEvertec(response.processUrl);
        setRequestId(response.requestId);
      } else {
        console.error("La respuesta de evertecAuth no es válida:", response);
      }
    } catch (error) {
      console.error("Error al procesar la transacción:", error);
    } finally {
      setProcessingEvertec(false);
    }
  };

  useEffect(() => {
    if (urlEvertec) {
      const createTransaction = async () => {
        try {
          const uploadedImageURLs = await saveImg(
            urlVoucherImg,
            socialSecurity
          );

          const createDataTransaction = {
            patientId: userId,
            method: "Evertec",
            state: "pendiente",
            evertecStatus: "pendiente",
            requestIdEvertec: requestId,
            orderId: "",
            date: new Date(),
            status: 0,
            type: typeOfLicense,
            amount: amount,
            scheduleDate: scheduleDate,
            scheduleRange: scheduleRange,
            dispensaryLocation: parseInt(dispensaryLocation),
            urlVoucherImg: uploadedImageURLs[0] || "",
          };

          const saveNewTransaction = await postEvertec(createDataTransaction);
          setIsButtonDisabled(false);

          P.init(urlEvertec);

          P.on("response", async function (response: any) {
            const status = response.status.status === "APPROVED" ? 1 : 0;
            const updateDataTransaction = {
              status: status,
              state: response.status.status,
              evertecStatus: response.status.status,
              requestIdEvertec: response.requestId,
              orderId: response.signature,
              date: response.status.date,
            };

            await updateTransaction(saveNewTransaction, updateDataTransaction);

            onEvertecStatusChange(response.status.status);
          });
        } catch (error) {
          console.error("Error al procesar la transacción:", error);
        }
      };

      createTransaction();
    }
  }, [urlEvertec]);

  return (
    <div className="flex flex-col justify-center items-center">
      <button
        className={`mt-6 bg-cdgreen text-white font-bold rounded-2xl py-6 w-full ${
          processingEvertec || isButtonDisabled
            ? "cursor-not-allowed opacity-50"
            : "hover:opacity-75"
        }`}
        type="submit"
        onClick={handleEvertecAuth}
        disabled={processingEvertec || isButtonDisabled}
      >
        {processingEvertec || isButtonDisabled
          ? "Iniciando Pasarela..."
          : "PAGAR AHORA"}
      </button>

      <img src={logoEvertec} className="w-36 mt-2" alt="logo evertec" />
      <div className="mt-2">
        <Modal title="FAQ EVERTEC" contents={<Faq />} />
      </div>
    </div>
  );
}

export default Evertec;
