import React, { useState, useEffect } from "react";
import referenceNames from "../../components/data/referenceNames";
import { getTransactionsByMonth } from "../../services/getTransactions";
import DatePicker from "react-datepicker";
import LoadingComponent from "../../components/LoadingComponent";

interface Amounts {
  [key: number]: number;
}

interface PaymentMethodCount {
  count: number;
  amounts: Amounts;
}

interface PaymentMethods {
  [method: string]: PaymentMethodCount;
}

interface TransactionTypeCount {
  [type: string]: number;
}

interface TransactionData {
  total: number;
  transactions: Array<{
    id: number;
    amount: number;
    type: string;
    date: string;
  }>;
  paymentMethodCount: PaymentMethods;
  transactionTypeCount: TransactionTypeCount;
}

function HomeSuperAdmin() {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionData, setTransactionData] =
    useState<TransactionData | null>(null);
  const [referenceId, setReferenceId] = useState<number | null>(6);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  const referencesArray = Object.entries(referenceNames) as unknown as [
    string,
    string
  ][];

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      setSelectedMonth(date.getMonth() + 1);
      setSelectedYear(date.getFullYear());
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (referenceId !== null) {
        try {
          setLoading(true);
          const result = await getTransactionsByMonth(
            selectedYear,
            selectedMonth,
            referenceId
          );
          setTransactionData(result as any);
        } catch (error) {
          console.error("Error fetching transaction data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [referenceId, selectedMonth, selectedYear]);

  return (
    <>
      <section className="bg-white p-5 mb-4 rounded">
        <h3 className="text-2xl mb-5">
          Realizar busqueda
          <p className="text-gray-400 text-base">
            Seleecione mes/año y dispensario a analizar.
          </p>
        </h3>

        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          renderMonthContent={(month, year) => `${month + 1}/${year}`}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          inline
        />

        <div className="flex flex-col lg:flex-row gap-5 mt-8">
          {referencesArray.map(([key, name]) => (
            <div key={key} className="w-full lg:w-1/5">
              <div
                className={`border border-gray-300 rounded text-center py-8 mt-2 cursor-pointer ${
                  referenceId === parseInt(key)
                    ? " bg-cdgreen text-white"
                    : "text-gray-500"
                }`}
                onClick={() => setReferenceId(parseInt(key))}
              >
                <h4 className="inline text-sm">{name}</h4>
              </div>
            </div>
          ))}
        </div>
      </section>

      {!loading && transactionData ? (
        <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-2">
          {/* Payment Methods Section */}
          <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
            <div className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6">
              <div>
                <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">
                  Metodos de Pagos
                </h6>
              </div>
            </div>
            <div className="p-6 overflow-x-scroll px-0 pt-0 pb-2">
              <table className="w-full min-w-[640px] table-auto">
                <thead>
                  <tr>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-bold uppercase text-blue-gray-400">
                        Metodo
                      </p>
                    </th>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-bold uppercase text-blue-gray-400">
                        Valor USD
                      </p>
                    </th>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-bold uppercase text-blue-gray-400">
                        Cantidad
                      </p>
                    </th>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-bold uppercase text-blue-gray-400">
                        Total USD
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(transactionData.paymentMethodCount).map(
                    ([method, details]) => (
                      <React.Fragment key={method}>
                        {Object.entries(details.amounts).map(
                          ([key, value], index) => (
                            <tr key={`${method}-${key}`}>
                              {index === 0 && (
                                <td
                                  className="py-3 px-5 border-b border-blue-gray-50"
                                  rowSpan={Object.keys(details.amounts).length}
                                >
                                  <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                                    {method} ({details.count})
                                  </p>
                                </td>
                              )}
                              <td className="py-3 px-5 border-b border-blue-gray-50">
                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
                                  {key}
                                </p>
                              </td>
                              <td className="py-3 px-5 border-b border-blue-gray-50">
                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
                                  {value}
                                </p>
                              </td>
                              <td className="py-3 px-5 border-b border-blue-gray-50">
                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
                                  USD {parseFloat(key) * value}
                                </p>
                              </td>
                            </tr>
                          )
                        )}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Transaction Types Section */}
          <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
            <div className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6">
              <div>
                <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">
                  Tipos de Licencias
                </h6>
              </div>
            </div>
            <div className="p-6 overflow-x-scroll px-0 pt-0 pb-2">
              <table className="w-full min-w-[640px] table-auto">
                <thead>
                  <tr>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-bold uppercase text-blue-gray-400">
                        Tipos
                      </p>
                    </th>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-bold uppercase text-blue-gray-400">
                        Total de licencias
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(transactionData.transactionTypeCount).map(
                    ([type, count]) => (
                      <tr key={type}>
                        <td className="py-3 px-5 border-b border-blue-gray-50">
                          <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                            {type}
                          </p>
                        </td>
                        <td className="py-3 px-5 border-b border-blue-gray-50">
                          <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
                            {count}
                          </p>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-4">
          <LoadingComponent />
        </div>
      )}
    </>
  );
}

export default HomeSuperAdmin;
