type paypalPaymentsData = {
  tableData: any;
};

type tableData = {
  id: string;
  date: string;
  type: string;
  method: string;
  amount: number;
  orderId: string;
  state: string;
};

function TableTransactions({ tableData }: paypalPaymentsData) {
  return (
    <div className="w-full mx-auto overflow-auto">
      <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
        <table className="min-w-full leading-normal">
          <thead className="bg-cdgreen">
            <tr>
              <th
                scope="col"
                className="p-3 text-sm font-semibold text-left tracking-wide text-white uppercase"
              >
                Fecha
              </th>
              <th
                scope="col"
                className="p-3 text-sm font-semibold text-left tracking-wide text-white uppercase"
              >
                Tipo
              </th>
              <th
                scope="col"
                className="p-3 text-sm font-semibold text-left tracking-wide text-white uppercase"
              >
                Método
              </th>
              <th
                scope="col"
                className="p-3 text-sm font-semibold text-left tracking-wide text-white uppercase"
              >
                Monto
              </th>
              <th
                scope="col"
                className="p-3 text-sm font-semibold text-left tracking-wide text-white uppercase"
              >
                Número de Orden
              </th>
              <th
                scope="col"
                className="p-3 text-sm font-semibold text-left tracking-wide text-white uppercase"
              >
                Estado
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 rounded-3xl">
            {tableData.map((tableData: tableData) => (
              <tr key={tableData.id} className="bg-white">
                <td className="px-5 py-5 text-sm">
                  <p className="text-gray-900">
                    {tableData.date
                      ?.split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                  </p>
                </td>
                <td className="px-5 py-5 text-sm">
                  <p className="text-gray-900 capitalize">{tableData.type}</p>
                </td>
                <td className="px-5 py-5 text-sm">
                  <p className="text-gray-900 capitalize">{tableData.method}</p>
                </td>
                <td className="px-5 py-5 text-sm">
                  <p className="text-gray-900">${tableData.amount}</p>
                </td>
                <td className="px-5 py-5 text-sm">
                  <p className="text-gray-900">{tableData.orderId}</p>
                </td>
                <td className="px-5 py-5 text-sm">
                  {(tableData.state === "APPROVED" ||
                    tableData.state === "COMPLETED") && (
                    <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                      <span className="absolute inset-0 bg-green-200 rounded-full opacity-50"></span>
                      <span className="relative">Aprobado</span>
                    </span>
                  )}
                  {tableData.state === "REJECTED" && (
                    <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-red-900">
                      <span className="absolute inset-0 bg-red-200 rounded-full opacity-50"></span>
                      <span className="relative">Denegado</span>
                    </span>
                  )}
                  {(tableData.state === "pendiente" ||
                    tableData.state === "PENDING") && (
                    <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-yellow-900">
                      <span className="absolute inset-0 bg-yellow-200 rounded-full opacity-50"></span>
                      <span className="relative">Pendiente</span>
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableTransactions;
