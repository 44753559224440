import FormField from "../../../components/FormFiel";
import referenceDispensaryLocation from "../../../components/data/referenceDispensaryLocation";

type PropsForm = {
  register: any;
  errors: any;
};

interface DispensaryOption {
  value: string | number;
  label: string;
}

function Region({ register, errors }: PropsForm) {
  // Convert the object into an array of objects
  const dispensaryOptions: DispensaryOption[] = Object.entries(
    referenceDispensaryLocation
  ).map(([value, label]) => ({
    value: parseInt(value),
    label: label,
  }));

  // Add the default option at the beginning
  dispensaryOptions.unshift({
    value: "",
    label: "Seleccionar lugar de recogido",
  });

  return (
    <>
      <FormField
        label="Tipo de licencia"
        id="typeOfLicense"
        name="tipo de licencia"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar tipo de licencia" },
          { value: "Nueva Licencia", label: "Nueva Licencia" },
          {
            value: "Nueva Licencia - Emancipado",
            label: "Nueva Licencia - Emancipado",
          },
          {
            value: "Nueva Licencia - Licencia Acompañante",
            label: "Nueva Licencia - Licencia Acompañante",
          },
          { value: "Renovación de Licencia", label: "Renovación de Licencia" },
          {
            value: "Renovación de Licencia - Emancipado",
            label: "Renovación de Licencia - Emancipado",
          },
          {
            value: "Renovación de Licencia - Licencia Acompañante",
            label: "Renovación de Licencia - Licencia Acompañante",
          },
        ]}
      />

      <FormField
        label="Lugar de recogido"
        id="location"
        name="lugar de recogido"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={dispensaryOptions}
      />
    </>
  );
}

export default Region;
