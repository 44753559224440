import React from "react";
import ActionCard from "../../components/ActionCard";
import { useNavigate } from "react-router-dom";
import { FaUsers, FaPaypal, FaLandmark, FaWhatsapp } from "react-icons/fa";

interface CardProps {
  description: string;
  icon: React.ReactElement;
  routePath: string;
  title: string;
}

function PatientsCards(): React.ReactElement {
  const navigate = useNavigate();

  const cards: CardProps[] = [
    {
      description: "Listado de todos los pacientes",
      icon: <FaUsers />,
      routePath: `listado`,
      title: "Lista de Pacientes",
    },
    {
      description: "Licencias nuevas y renovaciones",
      icon: <FaUsers />,
      routePath: `gestiones`,
      title: "Gestiones",
    },
    {
      description: "Licencias con prioridad",
      icon: <FaUsers />,
      routePath: `prioridad`,
      title: "Prioridad",
    },
  ];

  const handleOnClickCard = (routePath: string): void => {
    navigate(routePath);
  };

  return (
    <div className="flex flex-wrap justify-center w-full sm:justify-start ">
      {cards.map((card: CardProps, index: number) => (
        <ActionCard
          description={card.description}
          icon={card.icon}
          key={index}
          title={card.title}
          onClick={() => handleOnClickCard(card.routePath)}
        />
      ))}
    </div>
  );
}

export default PatientsCards;
