import FormField from "../../../components/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
};

function Region({ register, errors }: PropsForm) {
  return (
    <>
      <FormField
        label="Tipo de licencia"
        id="typeOfLicense"
        name="tipo de licencia"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar tipo de licencia" },
          { value: "Nueva Licencia", label: "Nueva Licencia" },
          {
            value: "Nueva Licencia - Emancipado",
            label: "Nueva Licencia - Emancipado",
          },
          {
            value: "Nueva Licencia - Licencia Acompañante",
            label: "Nueva Licencia - Licencia Acompañante",
          },
          { value: "Renovación de Licencia", label: "Renovación de Licencia" },
          {
            value: "Renovación de Licencia - Emancipado",
            label: "Renovación de Licencia - Emancipado",
          },
          {
            value: "Renovación de Licencia - Licencia Acompañante",
            label: "Renovación de Licencia - Licencia Acompañante",
          },
        ]}
      />

      <FormField
        label="Lugar de recogido"
        id="location"
        name="lugar de recogido"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar lugar de recogido" },
          { value: "0", label: "Email/Whatsapp " },
        ]}
      />
    </>
  );
}

export default Region;
