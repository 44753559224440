const referenceStatusArray: { label: string; value: number }[] = [
  { label: "Pendiente de aprobación de pago", value: 0 },
  { label: "Pendiente de cita medica", value: 1 },
  { label: "Pendiente de recomendación médica", value: 2 },
  { label: "Recomendación médica finalizada", value: 3 },
  { label: "Pendiente en Depto. de salud", value: 4 },
  //{ label: "Aprobado", value: 5 },
  { label: "En Revisión", value: 6 },
  //{ label: "Entregado", value: 7 }
];

export default referenceStatusArray;
