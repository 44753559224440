import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import userImg from "../../assets/images/userimg.webp";
import "tailwindcss/tailwind.css";
import { Link } from "react-router-dom";

interface Patient {
  name: string;
  lastName: string;
  email: string;
  phone: number;
  id: string;
  medicalRecordComplete: boolean;
  transactions?: Transaction[];
  country?: string;
  urlProfileImg?: string;
  date?: string;
  status: number;
  patientId?: number;
}

interface Transaction {
  id: string;
  amount: number;
  date: string;
  type: string;
}

type StatusProps = number;

function formatDate(date: string | undefined) {
  if (!date) return "Undefined or NULL";

  const formattedDate = new Date(date);

  if (isNaN(formattedDate.getTime())) {
    return "Not a valid date";
  }

  const day = formattedDate.getDate().toString().padStart(2, "0");
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const year = formattedDate.getFullYear();

  return `${day}-${month}-${year}`;
}

function getDaysDifference(date: string): number {
  const today = new Date();
  const parsedDate = new Date(date);
  const differenceInTime = today.getTime() - parsedDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.floor(differenceInDays);
}

function getPriorityStatus(date: string): JSX.Element {
  const differenceInDays = getDaysDifference(date);
  if (differenceInDays >= 30) {
    return (
      <span className="px-2 py-1 text-base rounded-full text-violet-600 bg-violet-200">
        Critico ({differenceInDays} días)
      </span>
    );
  } else if (differenceInDays >= 4) {
    return (
      <span className="px-2 py-1 text-base rounded-full text-red-600 bg-red-200 ">
        Urgente ({differenceInDays} días)
      </span>
    );
  } else if (differenceInDays >= 3) {
    return (
      <span className="px-2 py-1 text-base rounded-full text-yellow-600 bg-yellow-200 ">
        Media ({differenceInDays} días)
      </span>
    );
  } else {
    return (
      <span className="px-2 py-1 text-base rounded-full text-green-600 bg-green-200">
        Baja ({differenceInDays} días)
      </span>
    );
  }
}

function CardsPriority({
  data,
  referenceStatus,
}: {
  data: Patient[];
  referenceStatus: StatusProps;
}) {
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCards, setSelectedCards] = useState<string[]>([]);
  const [criticalCount, setCriticalCount] = useState(0);
  const [urgentCount, setUrgentCount] = useState(0);
  const [mediumCount, setMediumCount] = useState(0);
  const [lowCount, setLowCount] = useState(0);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  // Filter the 'data' array to only include patients with 'status' equal to 1
  const filteredData = data.filter(
    (patient) => patient.status === referenceStatus
  );

  // Sort 'filteredData' in ascending order based on the 'date' property
  filteredData.sort((a, b) => {
    const dateA = new Date(a.date || "");
    const dateB = new Date(b.date || "");
    return dateA.getTime() - dateB.getTime();
  });

  useEffect(() => {
    const counts = { critical: 0, urgent: 0, medium: 0, low: 0 };

    filteredData.forEach((patient) => {
      const daysDifference = getDaysDifference(patient.date || "");

      if (daysDifference >= 30) {
        counts.critical++;
      } else if (daysDifference >= 4) {
        counts.urgent++;
      } else if (daysDifference >= 3) {
        counts.medium++;
      } else {
        counts.low++;
      }
    });

    setCriticalCount(counts.critical);
    setUrgentCount(counts.urgent);
    setMediumCount(counts.medium);
    setLowCount(counts.low);
  }, [filteredData]);

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const toggleCardSelection = (id: string) => {
    setSelectedCards((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((cardId) => cardId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const performBulkAction = () => {
    console.log("Bulk action on selected cards:", selectedCards);
  };

  return (
    <div>
      <div className="mt-4">
        {selectedCards.length > 0 && (
          <button
            onClick={performBulkAction}
            className="px-4 py-2 bg-cdgreen text-white rounded-full"
          >
            Asignar Pacientes a Medico ({selectedCards.length})
          </button>
        )}
      </div>

      <div className="max-w-full mx-4 sm:mx-auto sm:px-6 lg:px-8">
        <div className="sm:flex sm:space-x-4">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total de Registros
                  </h3>
                  <p className="text-3xl font-bold text-black">
                    {filteredData.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-violet-800">
                    Critico
                  </h3>
                  <p className="text-3xl font-bold text-black">
                    {criticalCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-red-600">
                    Urgentes
                  </h3>
                  <p className="text-3xl font-bold text-black">{urgentCount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-yellow-600">
                    Media
                  </h3>
                  <p className="text-3xl font-bold text-black">{mediumCount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-green-600">
                    Bajas
                  </h3>
                  <p className="text-3xl font-bold text-black">{lowCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-10">
        {filteredData.slice(offset, offset + itemsPerPage).map((patient) => {
          return (
            <div
              key={patient.id}
              className="max-w-sm rounded overflow-hidden shadow-lg pt-10 relative"
            >
              {/* 
               <input
                type="checkbox"
                className="absolute top-2 right-2 cursor-pointer"
                onChange={() => toggleCardSelection(patient.id)}
                checked={selectedCards.includes(patient.id)}
              />
              */}

              <img
                src={patient.urlProfileImg || userImg}
                className="w-24 h-24 rounded-full mx-auto object-cover object-center"
                alt={`${patient.name} ${patient.lastName}`}
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">
                  {patient.name} {patient.lastName}
                </div>
                <p className="text-gray-700 text-base">
                  Email: {patient.email}
                </p>
                <p className="text-gray-700 text-base">
                  Phone: {patient.phone}
                </p>
                <p className="text-gray-700 text-base">
                  Pais: {patient.country}
                </p>
                <p className="text-gray-700 text-base">
                  Fecha: {formatDate(patient.date)}
                </p>
                <p className="text-gray-700 text-base mt-2">
                  Prioridad: {getPriorityStatus(patient.date || "")}{" "}
                </p>
              </div>
              <div className="px-6 py-4 flex justify-end">
                <Link
                  to={`/administrador/pacientes/gestiones/ver/?id=${patient.patientId}`}
                >
                  <button className="rounded-full p-2 text-sm font-semibold text-white bg-cdgreen hover:opacity-75">
                    Ir al Perfil
                  </button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"flex justify-center mt-4 mb-4 space-x-2 "}
          pageClassName={"px-3 py-2 border bg-white rounded-full"}
          previousClassName={"px-3 py-2 bg-cdgreen text-white rounded-full"}
          nextClassName={"px-3 py-2 bg-cdgreen text-white rounded-full"}
          activeClassName={
            "text-[#000000] border-solid border-2 border-cdgreen bg-transparent "
          }
        />
      )}
    </div>
  );
}

export default CardsPriority;
