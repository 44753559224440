import { useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import { FaCheckCircle } from "react-icons/fa";
import ChekcoutP from "../Checkout/CheckoutP";
import logoCanadoctors from "../../assets/images/logos/canadoctors/canadoctorsColors.svg";
import { Link } from "react-router-dom";
import AlertsMenssages from "../AlertsMenssages";
import CheckoutAr from "../Checkout/CheckoutAr";

const servicesAR = [
  {
    id: 1,
    name: "Solicitar Reprocann",
    price: "15.000",
  },
  {
    id: 2,
    name: "Renovar Reprocann",
    price: "15.000",
  },
  {
    id: 3,
    name: "Telemedicina",
    price: "15.000",
  },
];

const servicesPR = [
  {
    id: 1,
    name: "Nueva Licencia",
  },
  {
    id: 2,
    name: "Renovación de Licencia",
  },
  {
    id: 3,
    name: "Telemedicina",
  },
];

function SelectService() {
  const { user } = useAuthContext();
  const [handleClick, sethandleClick] = useState("");

  const dispensaryPrice = () => {
    switch (user.reference) {
      case 1:
        return "60";
      case 2:
        return "50";
      case 3:
        return "50";
      case 4:
        return "45";
      case 5:
        return "45";
      case 6:
        return "45";
      default:
        break;
    }
  };

  return (
    <div className="container mx-auto h-full px-4 ">
      <div className="container px-6 py-10 mx-auto">
        <div className="pb-4 flex justify-center">
          <img src={logoCanadoctors} alt="logo" width={400} />
        </div>
        {!handleClick && (
          <>
            <h1 className="mt-6 text-3xl font-medium text-center text-gray-500 capitalize">
              Nuestros Servicios
            </h1>

            {user.country === "AR" && (
              <div className="mt-6 space-y-8 xl:mt-12">
                {servicesAR.map((service) => (
                  <button
                    key={service.id}
                    className="w-full"
                    type="button"
                    onClick={() => sethandleClick(service.name)}
                  >
                    <div className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border text-gray-400 hover:border-[#00A099] hover:text-[#00A099] cursor-pointer rounded-xl">
                      <div className="flex items-center">
                        <FaCheckCircle className="text-3xl" />
                        <div className="flex flex-col items-start mx-5 space-y-1">
                          <h2 className="text-lg font-medium sm:text-2xl">
                            {service.name}
                          </h2>
                        </div>
                      </div>
                      <h2 className="text-2xl font-semibold sm:text-4xl">
                        ${service.price}
                        <span className="text-base font-medium"></span>
                      </h2>
                    </div>
                  </button>
                ))}
              </div>
            )}

            {user.country === "PR" && (
              <div className="mt-6 space-y-8 xl:mt-12">
                {servicesPR.map((service) => (
                  <button
                    key={service.id}
                    className="w-full"
                    type="button"
                    onClick={() => sethandleClick(service.name)}
                  >
                    <div className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border text-gray-400 hover:border-[#00A099] hover:text-[#00A099] cursor-pointer rounded-xl">
                      <div className="flex items-center">
                        <FaCheckCircle className="text-3xl" />
                        <div className="flex flex-col items-start mx-5 space-y-1">
                          <h2 className="text-lg font-medium sm:text-2xl">
                            {service.name}
                          </h2>
                        </div>
                      </div>
                      <h2 className="text-2xl font-semibold sm:text-4xl">
                        ${dispensaryPrice()}
                        <span className="text-base font-medium"></span>
                      </h2>
                    </div>
                  </button>
                ))}
              </div>
            )}
            <div className="pt-8 hover:opacity-80">
              <Link to={"/paciente/servicios"}>
                <AlertsMenssages
                  type="info"
                  message="Saber más sobre los servicios"
                />
              </Link>
            </div>
          </>
        )}

        {user.country === "PR" && (
          <>
            {handleClick && (
              <ChekcoutP typeLicence={handleClick} price={dispensaryPrice()} />
            )}
          </>
        )}

        {user.country === "AR" && <>{handleClick && <CheckoutAr />}</>}
      </div>
    </div>
  );
}

export default SelectService;
