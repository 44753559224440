import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";

function PublicRoute() {
  const { isAuthenticated, user } = useAuthContext();

  if (isAuthenticated) {
    let redirectTo;
    switch (user.rol) {
      case 1:
        redirectTo = "/paciente";
        break;
      case 2:
        redirectTo = "/doctor";
        break;
      case 3:
        redirectTo = "/dispensario";
        break;
      case 4:
        redirectTo = "/administrador";
        break;
      case 5:
        redirectTo = "/superadmin";
        break;
      default:
        redirectTo = "/";
    }
    return <Navigate to={redirectTo} />;
  }

  return <Outlet />;
}

export default PublicRoute;
