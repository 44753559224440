const baseURL = "https://canadoctors.herokuapp.com/transactions";
const baseURLUser = "https://canadoctors.herokuapp.com/users";

//muestra todas las trasacciones con los datos del usuario
const getTransactions = async () => {
  const [transactionResponse, patientResponse] = await Promise.all([
    fetch(`${baseURL}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
    fetch(`${baseURLUser}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  ]);

  try {
    const [transactionData, userData] = await Promise.all([
      transactionResponse.json(),
      patientResponse.json(),
    ]);

    const userMap = new Map(userData.map((user) => [user.id, user]));

    const transactionsWithPatientName = transactionData.map((transaction) => {
      const user = userMap.get(transaction.patientId);
      return {
        ...transaction,
        name: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        country: user.country,
        urlProfileImg: user.urlProfileImg,
        reference: user.reference,
      };
    });

    return transactionsWithPatientName;
  } catch (err) {
    // Handle any JSON parsing errors here
  }
};

//muestra todas las trasacciones con los datos del usuario por referencia
const getTransactionsReference = async (reference) => {
  const transactionResponse = await fetch(`${baseURL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const patientResponse = await fetch(`${baseURLUser}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  try {
    const transactionData = await transactionResponse.json();

    const userData = await patientResponse.json();
    const patientsByReference = userData.filter(
      (user) => user.rol === 1 && user.reference === reference
    );

    const transactionsWithPatientInfo = transactionData
      .filter((transaction) =>
        patientsByReference.find((user) => user.id === transaction.patientId)
      )
      .map((transaction) => {
        const associatedUser = patientsByReference.find(
          (user) => user.id === transaction.patientId
        );

        return {
          ...transaction,
          name: associatedUser.firstName,
          lastName: associatedUser.lastName,
          email: associatedUser.email,
          phone: associatedUser.phone,
          country: associatedUser.country,
          urlProfileImg: associatedUser.urlProfileImg,
        };
      });

    return transactionsWithPatientInfo;
  } catch (err) {
    // Handle any JSON parsing errors here
  }
};

// Función para agrupar las transacciones por mes y calcular los totales
const getTransactionsByMonth = async (year, month, reference) => {
  const transactions = await getTransactions();

  if (!transactions) {
    return;
  }

  const formattedMonth = month.toString().padStart(2, "0");
  const transactionsForMonthAndReference = transactions.filter(
    (transaction) => {
      const date = new Date(transaction.date);
      const transactionYear = date.getFullYear();
      const transactionMonth = (date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return (
        transactionYear === year &&
        transactionMonth === formattedMonth &&
        transaction.reference === reference
      );
    }
  );

  const transactionTypeCount = transactionsForMonthAndReference.reduce(
    (acc, transaction) => {
      const type = transaction.type;
      if (!acc.types[type]) {
        acc.types[type] = 0;
      }
      acc.types[type]++;
      return acc;
    },
    { types: {} }
  );

  const paymentMethodCount = transactionsForMonthAndReference.reduce(
    (acc, transaction) => {
      const method = transaction.method;
      if (!acc.methods[method]) {
        acc.methods[method] = {
          count: 0,
          amounts: {},
        };
      }
      acc.methods[method].count++;
      const amount = transaction.amount;
      if (!acc.methods[method].amounts[amount]) {
        acc.methods[method].amounts[amount] = 0;
      }
      acc.methods[method].amounts[amount]++;
      return acc;
    },
    { methods: {} }
  );

  return {
    transactionTypeCount: transactionTypeCount.types,
    paymentMethodCount: paymentMethodCount.methods,
  };
};

export { getTransactions, getTransactionsReference, getTransactionsByMonth };
