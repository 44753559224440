import { useEffect, useState } from "react";
import logoCanadoctors from "../../assets/images/logos/canadoctors/canadoctorsHite.svg";
import logoPlanteo from "../../assets/images/logos/elPlanteo/elPlanteoHite.svg";
import { FaEyeSlash, FaBars, FaSignOutAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import routesArray from "./routesArray";

function Panel({ children }: any) {
  const [open, setOpen] = useState(false);
  const { logout, user } = useAuthContext();
  const navidate = useNavigate();

  const filteredRoutes = routesArray.filter((route) => route.role === user.rol);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function toggleMenu() {
    setOpen(!open);
  }

  function handleClick() {
    if (window.innerWidth < 1024) {
      setOpen(false);
    }
  }

  const handleLogout = async () => {
    await logout();
    navidate("/");
  };

  return (
    <div className="bg-slate-50">
      <div className="flex overflow-hidden">
        <aside
          className={`fixed z-20 h-full top-0 left-0 flex lg:flex flex-shrink-0 flex-col w-80 transition-all duration-300 ${
            open ? "opacity-100 w-80" : "opacity-0 w-0 hidden"
          }`}
        >
          <div className="lg:w-80 w-screen h-screen bg-gradient-to-b from-cdgreen/100 to-cdgreenlight/100">
            <div className="mt-10 px-6">
              <div className="flex w-full justify-between pt-2 pb-10 items-center">
                <div className="w-full mr-4">
                  {user.reference !== 3 ? (
                    <img src={logoCanadoctors} alt="logo" width={180} />
                  ) : (
                    <img
                      src={logoPlanteo}
                      alt="logo"
                      width={320}
                      className="mt-2"
                    />
                  )}
                </div>
                {open && (
                  <button
                    className="lg:hidden flex p-2 items-center rounded-full bg-white shadow text-gray-500 text-md"
                    onClick={toggleMenu}
                  >
                    <FaEyeSlash className="text-xl" />
                  </button>
                )}
              </div>

              {filteredRoutes.map((route, index) => (
                <div key={index}>
                  {route.routes.map((subroute, index) => (
                    <div key={index}>
                      <p className="text-white ml-2 w-full border-b-2 pb-2 border-gray-100 mb-4 text-md font-normal">
                        {subroute.label}
                      </p>

                      {subroute.subroutes.map((subsubroute, index) => (
                        <Link
                          onClick={handleClick}
                          key={index}
                          to={subsubroute.to}
                          className="flex hover:text-gray-800 hover:rounded-lg rounded-lg text-white hover:bg-gray-300 items-center p-2 my-4 transition-colors duration-200 justify-start"
                        >
                          <span className="w-full flex justify-center items-center lg:justify-start">
                            <subsubroute.icon className="text-xl" />
                            <span className="mx-4">{subsubroute.title}</span>
                          </span>
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
              <div className="absolute bottom-0 my-10">
                <button
                  className="rounded-lg text-white flex items-center p-2 my-4 transition-colors duration-200 justify-start hover:text-gray-800"
                  onClick={handleLogout}
                >
                  <FaSignOutAlt className="text-xl" />
                  <span className="mx-4 text-md font-normal">Salir</span>
                </button>
              </div>
            </div>
          </div>
        </aside>
      </div>

      <div className="fixed z-30">
        <div className="px-2 p-1">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              {!open && (
                <button
                  className="flex p-3 items-center rounded-full bg-cdgreen shadow text-white text-md"
                  onClick={toggleMenu}
                >
                  <FaBars className="text-xl" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex-grow ${
          open ? "lg:ml-80" : ""
        } w-full lg:w-auto lg:flex-grow-0 transition-all duration-300`}
      >
        <div className="flex flex-col w-full items-center h-screen">
          <div className="mt-12 px-4 w-screen md:w-[70vw] overflow-auto h-screen">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
